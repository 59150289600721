import { Component, inject } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ApiSlug, AppApi } from '@tytapp/api';
import { LoggerService, Redirection, Shell, buildQuery } from '@tytapp/common';
import { UserService } from '@tytapp/user';
import { SlugService } from './slug.service';
import { STATIC_SLUGS } from './static-slugs';
import { isClientSide } from '@tytapp/environment-utils';


interface ResolvedSlug {
    type: string;
    slug: string;
    id?: string;
    uuid?: string;
    redirect?: boolean;
    url?: string;
}

@Component({ template: `<app-spinner></app-spinner>` })
export class SlugComponent {
    private router = inject(Router);
    private next = inject(ActivatedRoute);
    private redirection = inject(Redirection);
    private userService = inject(UserService);
    private shell = inject(Shell);
    private logger = inject(LoggerService).configure({ source: 'slugc' });
    private slugs = inject(SlugService);
    private appApi = inject(AppApi);

    static routes(): Route[] {
        let maxParams = 10;
        let routes: Route[] = [];
        for (let i = 0; i < maxParams; ++i) {
            routes.push(<Route>{
                path: `:slug${i > 0 ? `/${Array.from(Array(i)).map((_, i) => `:param${i + 1}`).join('/')}` : ``}`,
                component: SlugComponent,
                pathMatch: 'full',
                data: { reuse: false }
            });
        }

        return routes;
    }

    forwardedUrl: string;

    async nationHomeRedirect() {
        await this.userService.ready;
        const user = this.userService.user;

        const tytn_enabled = await this.shell.hasFeature('apps.web.enable_tytn');
        const openBeta = await this.shell.hasFeature('apps.web.nation_open_beta');

        const url = tytn_enabled && (openBeta || user?.can_access_tytnation) ?
            '/nation/home' :
            '/home';

        this.redirection.go(url, true);
    }

    async ngOnInit() {
        let next = this.next.snapshot;
        let originalUrl = '/' + next.url.join('/');
        const slug = next.paramMap.get('slug');

        if (!slug) return this.nationHomeRedirect();

        const params: string[] = [];

        for (let i = 1;; ++i) {
            let paramName = `param${i}`;
            if (!next.paramMap.has(paramName))
                break;
            let value = next.paramMap.get(paramName);
            params.push(value);
        }

        let pageUrl = '/' + [slug, ...params].join('/');

        this.logger.info(`Slug: ${slug}`);
        this.logger.info(`Params: ${params.join(', ')}`);
        this.logger.info(`Resolved page URL: ${pageUrl}`);

        if (isClientSide()) {
            // Before we check for custom pages or global slugs, check if there is a Cloudflare redirect.
            let destinationUrl = await this.lookupRedirect(pageUrl);
            if (destinationUrl) {
                this.logger.info(`Found a redirect to ${destinationUrl}`);
                this.redirection.go(destinationUrl);
                return;
            }
        }

        let resolvedSlug = await this.resolveRoute(pageUrl, params);

        this.logger.info(`Resolved slug`, resolvedSlug);

        // Support static slugs.

        if (!resolvedSlug) {
            if (STATIC_SLUGS[slug.toLowerCase()]) {
                resolvedSlug = { type: 'Navigation', slug: STATIC_SLUGS[slug.toLowerCase()], redirect: false };
            }
        }

        let url: string;

        if (resolvedSlug) {
            if (resolvedSlug.type === 'User') {
                if (!slug.startsWith('@')) {
                    this.redirection.go(`/@${slug}`, true);
                    return;
                }
            }
            else {
                if (resolvedSlug.type === 'CMS::Redirect') {
                    this.redirection.go(resolvedSlug.url, true);
                    return;
                }
            }
            let newUrl = `${this.urlBySlugType(resolvedSlug, params)}`;
            if (resolvedSlug.type !== 'CMS::Page')
                newUrl += `${params.length > 0 ? `/${params.join('/')}` : ''}`;

            if (newUrl === originalUrl) {
                this.logger.error(`Resolved internal URL (${newUrl}) already matches current URL (${originalUrl})`);
                url = '/not-found';
            } else {
                url = newUrl;
            }
        } else {
            this.logger.error(`Could not resolve route ${pageUrl}`);
            url = `/not-found`;
        }
        this.forwardedUrl = url;

        url = `${url}?${buildQuery(next.queryParams)}`

        this.logger.log(`Internal URL: ${url}`);
        this.router.navigateByUrl(url, {
            skipLocationChange: true,
            state: {
                slugged: true,
                slugUrl: `/${next.url.join('/')}`
            }
        });
    }

    private async lookupRedirect(url: string): Promise<string | undefined> {
        try {
            return (<{ url: string }>await this.appApi.lookupRedirect(url).toPromise())?.url;
        } catch (e) {
            if (e.statusCode !== 404) {
                if (e.json)
                    e = e.json();

                this.logger.error(`Failed to lookup redirect:`);
                this.logger.error(e);
            }
        }

        return undefined;
    }

    urlBySlugType(slug: ResolvedSlug, params: string[]) {
        try {
            return slug ? this.slugs.getContentUrl(slug) : `/not-found`;
        } catch (e) {
            this.logger.error(`Failed to get content URL for slug ${slug.type ?? 'unknown'}[${slug.slug ?? 'none'}]: ${e.stack || e}`);
            return `/not-found`;
        }
    }

    async resolveRoute(url: string, params: string[]): Promise<ResolvedSlug> {
        if (url.startsWith('/@'))
            return { slug: url.slice(2), type: 'User' };

        // Support all case sensitive variants for /live
        // TODO: this shouldn't be needed due to STATIC_SLUGS having `live`

        if (url.toLowerCase() === 'live' && params.length === 0) {
            return { type: 'Navigation', slug: '/live' };
        }

        let result: ApiSlug;

        try {
            result = await this.slugs.resolveRoute(url);
        } catch (e) {
            this.logger.error(`Caught error while resolving slug:`);
            this.logger.error(e);
            return null;
        }

        if (!result || result.type === 'User')
            return null;

        return <ResolvedSlug> result;
    }

}
